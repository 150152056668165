import {
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueChanges,
    KeyValueDiffer,
    KeyValueDiffers,
    Output,
} from "@angular/core";
import { User } from "../../../models/user.model";
import { BackendService } from "../../../services/backend.service";
import { ContactService } from "../../../services/contact.service";
import { isEmptyText } from "../../../helpers/textHelper";

const PAGE_SIZE = 20;

@Component({
    selector: "app-address-form",
    templateUrl: "./address-form.component.html",
    styleUrls: ["./address-form.component.scss"],
})
export class AddressFormComponent implements DoCheck {
    internalAddress: User;

    private internalAddressDiffer: KeyValueDiffer<string, any>;

    @Input()
    set address(user: User) {
        this.internalAddress = user;
        this.internalAddressDiffer = this.differs.find(this.internalAddress).create();
        if (!this.internalAddress.is_empty) {
            this.validate();
            if (!this.hasAutomaticPostcodeCheck) {
                this.manualStreetInput = true;
            }
        }
    }

    get address() {
        return this.internalAddress;
    }

    @Input()
    addressValidationErrors: {
        error?: string;
        reason: string;
        fields: string[];
    }[] = [];

    @Input()
    errors: any = {};

    @Input()
    availableCountries = [];

    @Input()
    isPostNL: boolean;

    @Output() onCapabilityChange = new EventEmitter();

    @Output() onValidatedFieldsChange = new EventEmitter();

    saveToContacts = false;

    searchTerm = "";

    page = 0;

    contacts = [];

    displayedContacts = [];

    showContactsModal = false;

    manualStreetInput = false;

    @Input()
    hasServicePoint: boolean = false;

    constructor(
        private backendService: BackendService,
        private contactService: ContactService,
        private differs: KeyValueDiffers,
    ) {}

    hasError(field: string, error?: string) {
        if (this.errors[field]) {
            if (error) {
                return this.errors[field].includes(error);
            }
            return this.errors[field];
        }
        return false;
    }

    checkValidations() {
        this.onValidatedFieldsChange.emit();
        this.address.is_empty = false;
        this.validate();
    }

    get isHouseNumberMandatory() {
        return this.address.country.toUpperCase() === "NL";
    }

    get housenrContainsNum() {
        return /\d/g.test(this.address.houseNumber);
    }

    selectContact(contact: User) {
        this.address.postcode = contact.postcode;
        this.address.houseNumber = contact.houseNumber;
        this.address.houseNumberExtra = contact.houseNumberExtra;
        this.address.city = contact.city;
        this.address.streetname = contact.streetname;
        this.address.phone = contact.phone;
        this.address.firstname = contact.firstname;
        this.address.middlename = contact.middlename;
        this.address.lastname = contact.lastname;
        this.address.email = contact.email;
        this.address.companyName = contact.companyName;
        this.address.country = contact.country;
        this.showContactsModal = false;
        this.updateManualStreetInput();
    }

    get hasAutomaticPostcodeCheck() {
        return this.address.country === "NL";
    }

    updateManualStreetInput() {
        if (!this.hasAutomaticPostcodeCheck) {
            this.manualStreetInput = true;
        }
    }

    updateAddress() {
        if (
            this.hasAutomaticPostcodeCheck &&
            this.address.houseNumber &&
            this.address.postcode &&
            !this.manualStreetInput
        ) {
            this.backendService
                .getAddressByPostcode(
                    this.address.postcode,
                    this.address.houseNumber,
                    this.address.houseNumberExtra,
                )
                .subscribe(
                    (addressRes) => {
                        this.address.streetname = addressRes.street;
                        this.address.city = addressRes.city;
                        this.validate();
                    },
                    (e) => {
                        if (e.success === false) {
                            this.errors.streetname = ["cannot-resolve"];
                        } else {
                            this.errors.streetname = ["server-error"];
                        }
                        this.address.streetname = "";
                        this.address.city = "";
                        this.validate();
                    },
                );
        }
    }

    private addExternalValidation(internalField: string, optionalExternalField?: string) {
        let externalField = optionalExternalField;
        if (!externalField) {
            externalField = internalField;
        }
        if (!this.addressValidationErrors) {
            return;
        }
        const errorIndex = this.addressValidationErrors.findIndex((e) =>
            e.fields.includes(externalField),
        );
        if (errorIndex >= 0) {
            const error = this.addressValidationErrors[errorIndex];
            const reason = error.error || error.reason;
            if (!this.errors[internalField]) {
                this.errors[internalField] = [reason];
            } else if (reason === "invalid" && !this.errors[internalField].includes("required")) {
                this.errors[internalField].push(reason);
            }
        }
    }

    validate(includeExternal: boolean = true) {
        this.errors = {};
        if (isEmptyText(this.address.lastname)) {
            this.errors.lastname = ["required"];
        }
        if (isEmptyText(this.address.email)) {
            this.errors.email = ["required"];
        }

        if (isEmptyText(this.address.postcode)) {
            this.errors.postcode = ["required"];
        }

        if (this.address.country && this.address.country.toUpperCase() === "NL") {
            if (isEmptyText(this.address.houseNumber)) {
                this.errors.housenr = ["required"];
            } else if (!this.housenrContainsNum) {
                this.errors.housenr = ["invalid"];
            } else if (this.isPostNL && Number.isNaN(Number(this.address.houseNumber))) {
                this.errors.housenr = ["PostNLError"];
            }
        }
        if (includeExternal) {
            this.addExternalValidation("postcode");
            this.addExternalValidation("lastname");
            this.addExternalValidation("housenr");
            this.addExternalValidation("housenr_extra");
            this.addExternalValidation("streetname");
            this.addExternalValidation("city");
            this.addExternalValidation("country");
        }

        if (isEmptyText(this.address.streetname)) {
            this.errors.streetname = ["required"];
        }

        if (isEmptyText(this.address.city)) {
            this.errors.city = ["required"];
        }

        if (isEmptyText(this.address.country)) {
            this.errors.country = ["required"];
        }

        if (this.hasServicePoint && isEmptyText(this.address.email)) {
            this.errors.email = ["required"];
        }

        return this.errors;
    }

    clearForm() {
        this.saveToContacts = false;
        this.showContactsModal = false;
        this.manualStreetInput = false;
        this.address.country = "NL";
    }

    private createContact() {
        this.backendService.createContact(this.address).subscribe(
            () => {
                this.backendService.showMessage(
                    true,
                    "Contactpersoon is toegevoegd aan adresboek!",
                );
            },
            () => {
                this.backendService.showMessage(
                    false,
                    "Foutcode 2d: Het toevoegen van dit contactpersoon is niet gelukt.",
                );
            },
        );
    }

    onSave() {
        if (this.saveToContacts) {
            this.createContact();
        }
    }

    showContactSelection(event) {
        this.contacts = [];

        event.preventDefault();

        this.contactService.getContacts().subscribe((d) => {
            this.contacts = d.contacts;
            this.onChangeSearchTerm();
        });

        this.showContactsModal = true;
    }

    onChangeSearchTerm() {
        const start = this.page * PAGE_SIZE;
        const end = (this.page + 1) * PAGE_SIZE - 1;
        let result = this.contacts;
        const lowerSearchTerm = this.searchTerm?.toLowerCase();
        if (this.searchTerm?.trim().length > 1) {
            result = this.contacts.filter((c) => c.getSearchTerm().indexOf(lowerSearchTerm) >= 0);
        }
        this.displayedContacts = result.slice(start, end);
    }

    addressChanges(changes: KeyValueChanges<string, any>) {
        changes.forEachChangedItem((record) => {
            if (record.key === "companyName" && !!record.previousValue !== !!record.currentValue) {
                this.onCapabilityChange.emit();
            } else if (record.key === "country") {
                this.onCapabilityChange.emit();
            }
        });
    }

    ngDoCheck(): void {
        const changes = this.internalAddressDiffer.diff(this.internalAddress);
        if (changes) {
            this.addressChanges(changes);
        }
    }
}
