import moment from "moment";
import { UserJSON } from "./json/userJSON";
import { E_USER_TYPES } from "../helpers/constants";
import { UserSaveJSON } from "./json/userSaveJSON";
import { AddressJSON } from "./json/addressJSON";

export class User {
    public constructor(
        public dateCreated: moment.Moment,
        public postcode: string,
        public houseNumber: string,
        public houseNumberExtra: string,
        public city: string,
        public streetname: string,
        public addressExtra: string,
        public initials: string,
        public firstname: string,
        public middlename: string,
        public lastname: string,
        public email: string,
        public companyName: string,
        public country: string,
        public type: number,
        public phone: string,
        public rights: string[],
        public userName?: string,
        public isActive?: boolean,
        public accountType?: number,
        public id?: number,
        public is_empty?: boolean,
        public kvk?: string,
        public btw?: string,
        public domain?: string,
        public iban?: string,
        public bic?: string,
        public sub_accounts?: { id: number; name: string }[],
        public webshopConnectorType?: string,
        public channelId?: string,
        public parentShopId?: number,
    ) {
        this.middlename = this.middlename === "-1" ? (this.middlename = null) : this.middlename;
    }

    static fromJSON(json: UserJSON) {
        let { account } = json;
        if (!account) {
            account = {
                user_name: undefined,
                active: false,
                type: undefined,
            };
        }
        let rights = [];
        if (json.rights) {
            rights = Object.entries(json.rights)
                .filter(([, value]) => value == "true")
                .map(([key]) => key);
        }

        return new User(
            moment(json.date_created),
            json.postcode,
            json.housenr,
            json.housenr_extra,
            json.city,
            json.streetname,
            json.address_extra,
            json.initials,
            json.firstname,
            json.middlename,
            json.lastname,
            json.email,
            json.company_name,
            json.country,
            json.type,
            json.phone,
            rights,
            account.user_name,
            account.active,
            account.type,
            json.id,
        );
    }

    toAddressJSON(): AddressJSON {
        return {
            id: this.id,
            date_created: this.dateCreated.format(),
            postcode: this.postcode,
            housenr: this.houseNumber,
            housenr_extra: this.houseNumberExtra,
            address_extra: this.addressExtra,
            company_name: this.companyName,
            city: this.city,
            country: this.country,
            streetname: this.streetname,
            phone: this.phone,
            type: this.type,
            initials: this.initials,
            firstname: this.firstname,
            middlename: this.middlename,
            lastname: this.lastname,
            email: this.email,
            gps_coords: undefined,
        };
    }

    toSaveJSON(): UserJSON {
        return {
            id: this.id,
            date_created: this.dateCreated.format(),
            postcode: this.postcode,
            housenr: this.houseNumber,
            housenr_extra: this.houseNumberExtra,
            address_extra: this.addressExtra,
            company_name: this.companyName,
            city: this.city,
            country: this.country,
            streetname: this.streetname,
            phone: this.phone,
            type: this.type,
            initials: this.initials,
            firstname: this.firstname,
            middlename: this.middlename,
            lastname: this.lastname,
            fullname: this.getFullName(),
            email: this.email,
            gps_coords: undefined,
            account: {
                user_name: this.userName,
                active: this.isActive,
                type: this.accountType,
            },
            viawebshop: {
                webshop_connector_type: this.webshopConnectorType,
                webshop_connector_config: this.channelId
                    ? `{"channel": "${this.channelId}"}`
                    : null,
                kvk: this.kvk,
                btw: this.btw,
                domain: this.domain,
                iban: this.iban,
                bic: this.bic,
                parent_shop_id: this.parentShopId,
            },
        };
    }

    toUpdateJSON(): UserSaveJSON {
        return {
            postcode: this.postcode,
            housenr: this.houseNumber,
            housenr_extra: this.houseNumberExtra,
            address_extra: this.addressExtra,
            company_name: this.companyName,
            city: this.city,
            country: this.country,
            streetname: this.streetname,
            phone: this.phone,
            type: this.type,
            initials: this.initials,
            firstname: this.firstname,
            middlename: this.middlename,
            lastname: this.lastname,
            email: this.email,
            viawebshop: {
                webshop_connector_type: this.webshopConnectorType,
                webshop_connector_config: this.channelId
                    ? `{"channel": "${this.channelId}"}`
                    : null,
                kvk: this.kvk,
                btw: this.btw,
                domain: this.domain,
                iban: this.iban,
                bic: this.bic,
                parent_shop_id: this.parentShopId,
            },
        };
    }

    /**
     * Generates the full displayname for an user.
     * The format is "firstname middlename lastname".
     * If the user does not have a middlename the function will return "firstname lastname".
     * @returns {string} Full name of the user.
     */
    public getFullName(): string {
        const middlename = this.middlename ? `${this.middlename} ` : "";
        return `${this.firstname} ${middlename}${this.lastname}`;
    }

    public getSearchTerm(): string {
        return [this.getFullName(), this.postcode, this.city, this.email].join("|").toLowerCase();
    }

    static createEmpty() {
        const temp = new User(
            moment(),
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "NL",
            E_USER_TYPES.NON_INTEGRATED_USER,
            "",
            [],
            "",
            true,
            E_USER_TYPES.NON_INTEGRATED_USER,
        );
        temp.is_empty = true;
        return temp;
    }
}
