import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AfterViewInit, ChangeDetectorRef, Component } from "@angular/core";
import { Router } from "@angular/router";
import { trigger, style, animate, transition } from "@angular/animations";
import { AuthService } from "../../services/auth.service";
import { E_USER_TYPES } from "../../helpers/constants";
import { User } from "../../models/user.model";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    animations: [
        trigger("fontSizeAnimation", [
            transition(":enter", [style({ "font-size": "0" }), animate("0.3s ease-out")]),
        ]),
    ],
})
export class SidebarComponent implements AfterViewInit {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    collapsed: boolean;
    disableAnimation = true;

    constructor(
        private auth: AuthService,
        private store: Store<{ currentUser: User }>,
        private router: Router,
        private changeDetector: ChangeDetectorRef,
    ) {
        this.collapsed = localStorage.getItem("collapsedMenu") === "true";
    }

    ngAfterViewInit() {
        this.disableAnimation = false;
        this.changeDetector.detectChanges();
    }

    get showDashboard() {
        return this.auth.getAccountRole() !== E_USER_TYPES.SUPPORT;
    }

    get showWebshops() {
        return (
            this.auth.getAccountRole() === E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() === E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    get showContacts() {
        return (
            this.auth.getAccountRole() === E_USER_TYPES.VIADC ||
            this.auth.getAccountRole() === E_USER_TYPES.VIAWEBSHOP ||
            this.auth.getAccountRole() === E_USER_TYPES.MODERATOR ||
            this.auth.getAccountRole() === E_USER_TYPES.GLOBAL_ADMIN
        );
    }

    logout(): void {
        this.auth.logout();
        this.router.navigateByUrl("/login");
    }

    toggleSideBar() {
        this.collapsed = !this.collapsed;
        localStorage.setItem("collapsedMenu", this.collapsed.toString());
    }
}
