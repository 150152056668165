import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "errorToText" })
export class ErrorTextPipe implements PipeTransform {
    /* eslint-disable class-methods-use-this */

    fieldToName(field: string) {
        switch (field) {
            case "country":
                return "land";
            case "firstname":
                return "voornaam";
            case "middlename":
                return "tussenvoegsel";
            case "lastname":
                return "achternaam";
            case "company_name":
                return "bedrijfsnaam";
            case "postcode":
                return "postcode";
            case "housenr":
                return "huisnummer";
            case "housenr_extra":
                return "huisnummertoevoeging";
            case "streetname":
                return "straat";
            case "address_extra":
                return "tweede adresregel";
            case "city":
                return "plaatsnaam";
            case "external":
                return "vervoerder";
            case "mark":
                return "kenmerk";
            case "instructions":
                return "opmerking";
            case "email":
                return "email";
            case "phone":
                return "telefoonnummer";
            case "options":
                return "pakket opties";
            case "type":
                return "pakket type";
            case "weight":
                return "gewicht";
            default:
                return "onbekend";
        }
    }

    getMaxFieldLength(field: string) {
        switch (field) {
            case "firstname":
                return "50";
            case "middlename":
                return "30";
            case "lastname":
                return "50";
            case "company_name":
                return "55";
            case "postcode":
                return "16";
            case "housenr":
                return "10";
            case "housenr_extra":
                return "5";
            case "address_extra":
                return "255";
            case "streetname":
                return "64";
            case "city":
                return "30";
            case "mark":
                return "30";
            case "instructions":
                return "144";
            case "email":
                return "255";
            case "phone":
                return "15";
            default:
                return "x";
        }
    }

    getMinFieldLength(field: string) {
        switch (field) {
            case "lastname":
                return "1";
            case "postcode":
                return "1";
            case "streetname":
                return "1";
            case "city":
                return "1";
            default:
                return "x";
        }
    }

    transform(error: string, field: string) {
        if (!error) {
            return "Deze validatiefout is niet gevonden.";
        }
        switch (error) {
            case "required":
                return `Het veld ${this.fieldToName(field)} is verplicht.`;
            case "invalid":
                return `De waarde van het veld '${this.fieldToName(field)}' is ongeldig.`;
            case "not_allowed":
                return `Jouw account heeft geen toegang tot deze waarde voor het veld '${this.fieldToName(
                    field,
                )}'`;
            case "string_too_long":
            case "too_long":
                return `Het veld '${this.fieldToName(
                    field,
                )}' is te lang. Deze mag max. ${this.getMaxFieldLength(field)} tekens bevatten.`;
            case "too_short":
            case "string_too_short":
                return `Het veld '${this.fieldToName(
                    field,
                )}' is te kort. Deze moet min. ${this.getMinFieldLength(field)} tekens bevatten.`;
            case "combination_invalid":
                if (field == "external" || field == "id_check") {
                    return "De combinatie id_check en vervoerder is niet mogelijk.";
                }
                if (field == "housenr" || field == "country") {
                    return "Wanneer het om een buitenlandpakket gaat dient het huisnummer ingevuld te zijn.";
                }
                return "De combinatie tussen een of meerdere invoervelden is niet mogelijk";
            default:
                return "Onbekende validatiefout";
        }
    }
}
// eternal name -> id check
// housenr -> buitenland
