import { Store } from "@ngrx/store";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as Sentry from "@sentry/browser";
import { catchError } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { addMessage } from "../../store/actions/message.actions";
import { getValidationErrorMessages } from "../../helpers/errorHelper";
import { AuthService } from "../../services/auth.service";
import { BackendService } from "../../services/backend.service";
import { CountryService } from "../../services/country.service";
import { User } from "../../models/user.model";
import { Package } from "../../models/package.model";
import { E_PACKAGE_TYPES, E_USER_TYPES } from "../../helpers/constants";
import { E_EXTERNAL_PARTIES, SHIPPING_COMPANIES } from "../../helpers/constants/transporters";
import { ServicePoint } from "../../models/servicePoint.model";
import { Message } from "../../models/message";
import { AddressFormComponent } from "./address-form/address-form.component";
import { ViaWebshop } from "../../models/viawebshop.model";
import { UserTypes } from "../../models/user.types";

type AddressOption = {
    id?: number;
    name: string;
};

@Component({
    selector: "app-new-package",
    templateUrl: "./new-package.component.html",
    styleUrls: ["./new-package.component.scss"],
})
export class NewPackageComponent implements OnInit {
    currentUser$: Observable<User> = this.store.select((state) => state.currentUser);

    package = Package.createEmpty();

    errors: any = {};

    validationErrors: { reason: string; error: string; fields: string[] }[] = [];

    addressValidationErrors: { reason: string; fields: string[] }[] = [];

    submittedOnce: boolean = false;

    addressOptions: AddressOption[] = [];

    sender: AddressOption;

    recipient: AddressOption;

    @ViewChild(AddressFormComponent)
    addressForm: AddressFormComponent;

    manualStreetInput = false;

    availableCountries = [];

    availableCarriers = [];

    chooseServicePoint: boolean = false;

    capabilities: {
        capabilities: string[];
        parcel_type: string;
    }[] = [];

    get packageTypes(): { type: number; label: string; disabled: boolean; visible: boolean }[] {
        return [
            {
                type: 5,
                label: "0 - 15kg",
                disabled: false,
                visible: true,
            },
            {
                type: 6,
                label: "15 - 30kg",
                disabled: false,
                visible: true,
            },
            {
                type: 2,
                label: "Brievenbuspakket",
                disabled: this.hasServicePoint,
                visible: !this.isForeignPackage || this.canUseForeignMailbox,
            },
            {
                type: 11,
                label: "Envelop",
                disabled: this.hasServicePoint,
                visible: !this.isForeignPackage,
            },
        ];
    }

    get canUseForeignMailbox() {
        return (
            this.availableCarriers
                .map((carrier) => carrier.value)
                .includes(E_EXTERNAL_PARTIES.BPOST) && this.package.recipient?.country === "BE"
        );
    }

    public get isDHLDE(): boolean {
        return (
            this.package.external_id === E_EXTERNAL_PARTIES.DHL_DE ||
            ([
                E_EXTERNAL_PARTIES.VIATIM,
                E_EXTERNAL_PARTIES.DHL_4Y1,
                E_EXTERNAL_PARTIES.DHL_4Y2,
            ].includes(this.package.external_id) &&
                (this.package.recipient?.country === "DE" || this.package.sender?.country === "DE"))
        );
    }

    constructor(
        private auth: AuthService,
        private backend: BackendService,
        private router: Router,
        private countryService: CountryService,
        private activatedRoute: ActivatedRoute,
        private store: Store<{ messages: Message[]; currentUser: User }>,
    ) {
        this.currentUser$.subscribe((user: User) => {
            if (user) {
                this.addressOptions.push({ name: "Klant" });
                this.addressOptions.push({ id: user.id, name: user.companyName });
                if (user instanceof ViaWebshop) {
                    this.addressOptions.push(...user.subAccounts);
                }
                this.availableCarriers = SHIPPING_COMPANIES.filter((company) =>
                    user.rights.includes(`can_use_${company.code}`),
                );
                if (this.package.external_id == null) {
                    if (this.availableCarriers.length >= 1) {
                        this.package.external_id = this.availableCarriers[0].value;
                    } else {
                        this.package.external_id = null;
                    }
                }
                [this.recipient, this.sender] = this.addressOptions;
                this.updateAvailableCountries();
                this.updateCapabilities();
            }
        });
    }

    updateAddressValidationErrors(prefix: string) {
        const result = this.validationErrors
            .filter((error) => error.fields.some((field) => field.startsWith(prefix)))
            .map((error) => ({
                fields: error.fields.map((field) => field.replace(`${prefix}.`, "")),
                reason: error.reason || error.error,
            }));
        result.forEach((x) => this.addressValidationErrors.push(x));
        this.addressValidationErrors.concat(result);
    }

    ngOnInit() {
        this.updateCapabilities();
        this.activatedRoute.params.subscribe((params) => {
            const tracktrace = params["package"];
            if (tracktrace) {
                this.getPackage(tracktrace);
            }
        });
        this.clearForm();
    }

    changeSender() {
        if (!this.sender.id) {
            [, this.recipient] = this.addressOptions;
            this.package.sender = User.createEmpty();
            delete this.package.recipient;
            this.updateAddressValidationErrors("sender");
        } else {
            [this.recipient] = this.addressOptions;
            this.package.recipient = User.createEmpty();
            delete this.package.sender;
            this.updateAddressValidationErrors("recipient");
        }
        this.updateAvailableCountries();
        this.updateCapabilities();
    }

    changeRecipient() {
        if (!this.recipient.id) {
            [, this.sender] = this.addressOptions;
            this.package.recipient = User.createEmpty();
            delete this.package.sender;
            this.updateAddressValidationErrors("sender");
        } else {
            [this.sender] = this.addressOptions;
            this.package.sender = User.createEmpty();
            delete this.package.recipient;
            this.updateAddressValidationErrors("recipient");
        }
        this.updateAvailableCountries();
        this.updateCapabilities();
    }

    get isForeignPackage() {
        return (
            (this.package.recipient && this.package.recipient.country !== "NL") ||
            (this.package.sender && this.package.sender.country !== "NL")
        );
    }

    get hasValidationErrors() {
        return (
            Object.keys(this.errors).length > 0 || Object.keys(this.addressForm.errors).length > 0
        );
    }

    save() {
        this.doSave().subscribe(async (isSaved) => {
            if (isSaved) {
                this.backend.showMessage(true, "Pakket is aangemaakt!");
                this.addressForm.onSave();
                await this.router.navigateByUrl("/packages");
            }
        });
    }

    saveAndAdd() {
        this.doSave().subscribe(async (isSaved) => {
            if (isSaved) {
                this.backend.showMessage(true, "Pakket is aangemaakt!");
                this.addressForm.onSave();
                this.addressForm.clearForm();
                this.clearForm();
                await this.router.navigateByUrl("/packages/create");
            }
        });
    }

    get isPostNL() {
        return (
            this.package.external_id == E_EXTERNAL_PARTIES.MYPARCEL ||
            this.package.external_id == E_EXTERNAL_PARTIES.PARCELPRO
        );
    }

    private clearForm() {
        this.submittedOnce = false;
        this.package = Package.createEmpty();
        this.package.type = E_PACKAGE_TYPES.STANDARD_PACKAGE_L15KG;
        this.errors = {};
        this.package.sender.id = 1;
        this.package.recipient.id = undefined;
        this.package.recipient.country = "NL";
        this.package.length = undefined;
        this.package.width = undefined;
        this.package.height = undefined;
        this.package.weight = undefined;
        this.package.value = undefined;
        if (this.package.external_id == null) {
            if (this.availableCarriers.length >= 1) {
                this.package.external_id = this.availableCarriers[0].value;
            } else {
                this.package.external_id = null;
            }
        }
    }

    private doSave(): Observable<boolean> {
        this.submittedOnce = true;
        this.validate();
        this.addressForm.validate();
        if (!this.hasValidationErrors) {
            this.updateUsers();
            return this.backend.createPackage(this.package).pipe(
                catchError((e) => {
                    const validationErrorMessages = getValidationErrorMessages(e.error.errors);
                    validationErrorMessages.forEach((validationErrorMessage) => {
                        this.store.dispatch(addMessage({ message: validationErrorMessage }));
                    });
                    return of(false);
                }),
            );
        }
        this.backend.showMessage(
            false,
            "Het pakket kan niet aangemaakt worden want er zijn nog fouten!",
        );
        return of(false);
    }

    private updateUsers() {
        if (this.sender.id) {
            this.package.sender = User.createEmpty();
            this.package.sender.id = this.sender.id;
            this.package.recipient.type = E_USER_TYPES.NON_INTEGRATED_USER;
        }
        if (this.recipient.id) {
            delete this.package.service_point;
            this.package.recipient = User.createEmpty();
            this.package.recipient.id = this.recipient.id;
            this.package.sender.type = E_USER_TYPES.NON_INTEGRATED_USER;
        }
        this.package.owner = User.createEmpty();
        this.package.owner.id = this.auth.getOwnUserId();
    }

    getPackage(tracktrace: string) {
        this.backend.getPackageByTrackTrace(tracktrace).subscribe((oldPackage) => {
            this.package = oldPackage;
            this.submittedOnce = true;
            if (this.package.validation_errors) {
                this.validationErrors = this.package.validation_errors.errors;
            }
            if (this.package.sender.type !== UserTypes.nonIntegratedUser) {
                this.sender = this.addressOptions.find((s) => s.id === this.package.sender.id);
                [this.recipient] = this.addressOptions;
                this.updateAddressValidationErrors("recipient");
            } else {
                this.recipient = this.addressOptions.find(
                    (s) => s.id === this.package.recipient.id,
                );
                [this.sender] = this.addressOptions;
                this.updateAddressValidationErrors("sender");
            }
            this.validate();
            this.updateAvailableCountries();
            this.updateCapabilities();
            this.manualStreetInput = true;
        });
    }

    hasError(field: string) {
        if (this.errors[field]) {
            return this.errors[field] && this.errors[field].length > 0;
        }
        return false;
    }

    getErrors(field: string) {
        if (this.errors[field]) {
            return this.errors[field];
        }
        return [];
    }

    private addExternalValidation(internalField: string, optionalExternalField?: string) {
        let externalField = optionalExternalField;
        if (!externalField) {
            externalField = internalField;
        }
        if (!this.validationErrors) {
            return;
        }
        const errorIndex = this.validationErrors.findIndex((e) => e.fields.includes(externalField));
        if (errorIndex >= 0) {
            const error = this.validationErrors[errorIndex];
            const reason = error.reason || error.error;
            if (!this.errors[internalField]) {
                this.errors[internalField] = [reason];
            } else if (reason === "invalid" && !this.errors[internalField].includes("required")) {
                this.errors[internalField].push(reason);
            }
            this.validationErrors.splice(errorIndex, 1);
        }
    }

    checkValidations() {
        if (this.submittedOnce) {
            this.validate();
        }
    }

    validate() {
        this.errors = {};
        if (!(this.package.type && this.package.type in E_PACKAGE_TYPES)) {
            this.errors.packageType = ["required"];
        }

        if (this.package.type == 2 && this.hasServicePoint) {
            this.errors.packageType = ["invalid"];
        }

        if (this.isDHLDE && [null, undefined, "", 0].includes(this.package.weight)) {
            this.errors.weight = ["required"];
        }

        if (this.isDHLDE && this.package.weight < 0) {
            this.errors.weight = ["invalid"];
        }

        this.addExternalValidation("packageType", "type");

        if (!this.package.external_id) {
            this.errors.external = ["required"];
        }
        this.validateOptions();
        this.addExternalValidation("mark");
        this.addExternalValidation("instructions");
        this.addExternalValidation("options");
        this.addExternalValidation("email");
        this.validationErrors = this.validationErrors.filter(
            (error) =>
                !error.fields.every(
                    (field) => field.startsWith("recipient.") || field.startsWith("sender."),
                ),
        );
        if (this.validationErrors.length > 0) {
            this.validationErrors.forEach((error) => {
                Sentry.captureMessage(`unknown external validation error ${JSON.stringify(error)}`);
            });
        }
    }

    updateAvailableCountries() {
        if (this.package && this.package.external_id && this.sender) {
            if (!this.sender.id) {
                this.availableCountries = this.countryService.countriesThatExternalCanSendFrom(
                    this.package.external_id,
                );
            } else {
                this.availableCountries = this.countryService.countriesThatExternalCanSendTo(
                    this.package.external_id,
                );
            }
        } else {
            this.availableCountries = [];
        }
    }

    updateExternal(external: number) {
        this.package.service_point = undefined;
        this.package.external_id = external;
        if (external == E_EXTERNAL_PARTIES.DPD) {
            this.package.id_check = false;
        }
        this.package.type = this.packageTypes[0].type;
        this.checkValidations();
        this.updateAvailableCountries();
        this.updateCapabilities();
    }

    updateCapabilities() {
        if (!this.package.recipient || !this.package.external_id) {
            this.capabilities = [];
        } else {
            let destinationType = "HOME";
            if (this.package.service_point) {
                destinationType = "SERVICE_POINT";
            } else if (this.package.recipient.companyName) {
                destinationType = "BUSINESS";
            }
            this.backend
                .getCapabilitiesByPackageInformation(
                    this.package.recipient.country,
                    this.package.external_id,
                    destinationType,
                )
                .subscribe((result) => {
                    this.capabilities = result;
                });
        }
    }

    get currentCapabilities() {
        let type: string;
        if (this.package.type === E_PACKAGE_TYPES.STANDARD_PACKAGE_L15KG) {
            type = "NORMAL";
        } else if (this.package.type === E_PACKAGE_TYPES.STANDARD_PACKAGE_H15KG) {
            type = "HEAVY";
        } else {
            type = "MAILBOX";
        }
        return (
            this.capabilities.find(({ parcel_type }) => parcel_type === type)?.capabilities || []
        );
    }

    validateOptions() {
        const NO_OPTIONS_AVAILABLE =
            !this.package.proof_of_delivery &&
            !this.package.insured &&
            !this.package.no_neighbours &&
            !this.package.id_check &&
            !this.package.premium &&
            !this.package.same_day &&
            !this.package.evening_delivery;
        let isValid = true;
        if (this.package.type === 2) {
            isValid = isValid && NO_OPTIONS_AVAILABLE;
        }
        if (this.package.service_point != null) {
            if (
                this.package.external_id !== E_EXTERNAL_PARTIES.PARCELPRO &&
                this.package.external_id !== E_EXTERNAL_PARTIES.MYPARCEL
            ) {
                isValid = isValid && !this.package.proof_of_delivery && !this.package.id_check;
            }
            isValid = isValid && !this.package.no_neighbours;
        }
        if (!isValid) {
            this.errors.options = ["invalid"];
        } else {
            delete this.errors.options;
        }
    }

    editPackage(): void {
        this.validate();
        this.addressForm.validate(false);
        if (!this.hasValidationErrors) {
            if (this.package.type === 2) {
                this.package.insured = false;
                this.package.no_neighbours = false;
                this.package.proof_of_delivery = false;
                this.package.id_check = false;
                this.package.same_day = false;
                this.package.evening_delivery = false;
                this.package.premium = false;
            }
            this.updateUsers();

            this.backend
                .editPackage(this.package)
                .pipe(
                    catchError((): Observable<boolean> => {
                        this.backend.showMessage(
                            false,
                            "Helaas is het niet gelukt om dit pakket aan te passen",
                        );
                        return of(false);
                    }),
                )
                .subscribe(async (): Promise<void> => {
                    this.backend.showMessage(true, "Het aanpassen van het pakket is gelukt!");
                    this.addressForm.onSave();
                    await this.router.navigateByUrl("/packages");
                });
        } else {
            this.backend.showMessage(
                false,
                "Het lijkt erop dat er nog wat fouten zijn opgetreden bij dit pakket!",
            );
        }
    }

    chooseServiceLocation(): void {
        this.chooseServicePoint = true;
    }

    closeDialog(): void {
        this.chooseServicePoint = false;
    }

    setServicePoint(servicePoint: ServicePoint) {
        this.package.service_point = servicePoint;
        this.chooseServicePoint = false;
        this.updateCapabilities();
    }

    clearServicePoint() {
        this.package.service_point = undefined;
        this.updateCapabilities();
    }

    trackByFn(index: number, _: any): any {
        return index;
    }

    get showServicePoint(): boolean {
        return (
            this.package.recipient.country == "NL" &&
            ![E_EXTERNAL_PARTIES.RJP, E_EXTERNAL_PARTIES.CYCLOON].includes(this.package.external_id)
        );
    }

    get hasServicePoint(): boolean {
        return !!this.package.service_point && !!this.package.service_point.name;
    }
}
